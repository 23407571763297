import { ListItemText, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import currencyFormat from '../../../../helpers/currencyFormat';
import type { LineItem } from '../../../../types/schema';

export default function MobileRowPanel( { lineItem }: { lineItem: LineItem } ) {
	return (
		<Fragment>
			<ListItemText
				primaryTypographyProps={{ component: 'div' }}
				secondaryTypographyProps={{ component: 'div' }}
				sx={{ my: 0, alignSelf: 'start' }}
				primary={(
					<Stack spacing={.5}>
						{lineItem.name && (
							<Typography color='text.secondary' fontWeight={500}>
								{lineItem.name}
							</Typography>
						)}
						{lineItem.order?.number && (
							<Typography variant='h6'>
								{lineItem.order.number}
							</Typography>
						)}
						{lineItem.order?.client?.name || lineItem.order?.client?.contact && (
							<Typography color='text.secondary'>
								{lineItem.order?.client?.name || lineItem.order?.client?.contact}
							</Typography>
						)}
						{lineItem.order?.serviceDate ? (
							<Typography color='text.secondary'>
								{format( lineItem.order?.serviceDate, 'PPp' )}
							</Typography>
						) : (
							<Typography color='text.secondary'>
								{format( lineItem?.order?.updatedAt, 'PPp' )}
							</Typography>
						)}
						{/*{lineItem.order.companyLocation?.address?.line1 && (*/}
						{/*	<Typography color='text.secondary'>*/}
						{/*		{lineItem.order.companyLocation?.address?.line1}*/}
						{/*	</Typography>*/}
						{/*)}*/}
					</Stack>
				)}
			/>
			<Stack spacing={1} textAlign='right' sx={{ alignSelf: 'start', alignItems: 'end' }}>
				{lineItem.quantity && (
					<Typography variant='h6'>
						{lineItem.quantity}
					</Typography>
				)}
				{lineItem.price && (
					<Typography>
						{currencyFormat( lineItem.price )}
					</Typography>
				)}
				{lineItem.unit && (
					<Typography>
						{lineItem.unit}
					</Typography>
				)}
				{lineItem.quantity && lineItem.price && (
					<Typography>
						{currencyFormat( lineItem.quantity * lineItem.price )}
					</Typography>
				)}
			</Stack>
		</Fragment>
	);
}
