import { CompanyTagsType } from '@/modals/companyTags';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';

export default function ItemReportQuickFilterActions( setStatus?: ( status: string ) => void ) {
	const router = useRouter();
	const event = useEvents();
	const { staff } = useUserInfo();
	const lineItemTags: CompanyTagsType[] = staff.company?.tags?.lineItemTags?.filter( ( tag ) => tag?.forOrder ) || [];
	const lineItemsTagsNames = lineItemTags?.map( ( tag ) => tag.name ) || [];
	
	return [
		...!isEmpty( lineItemsTagsNames ) ? [ 'No Status', ...lineItemsTagsNames ].map( ( status ) => ( {
			name   : status,
			onClick: () => {
				if ( setStatus ) {
					setStatus( status === 'No Status' ? '' : status );
				} else {
					router.push( {
						pathname: router.asPath.split( '?' )[ 0 ],
						query   : {
							s: encodeURIComponent( btoa( JSON.stringify( {
								filters: [ {
									id   : 'status',
									value: {
										$eq : status,
										temp: { checked: true },
									},
								} ],
							} ) ) ),
						},
					}, undefined, { shallow: true } ).then();
					event.emit( 'url.filter', true );
				}
				
			},
		} ) ) : [],
	].filter( Boolean );
}

