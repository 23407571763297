import ItemReportQuickFilterActions from '@/pages/dashboard/management/reports/actions/itemReportQuickFilterActions';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { Close as CloseIcon, PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import { endOfDay, startOfMonth } from 'date-fns';
import { isEmpty } from 'lodash-es';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { LineItemsRead } from '../../data/management/lineItem.graphql';
import { baseOrderColumns } from '../../pages/dashboard/management/reports/itemReport';
import ItemReportFilters from '../../pages/dashboard/management/reports/itemReportFilters';
import { basePurchaseColumns } from '../../pages/dashboard/management/reports/purchaseReport';
import { LineItem, QueryLineItemsReadArgs } from '../../types/schema';
import Form from '../form';
import GraphqlTable from '../graphqlTable';
import PageWrapper from '../page/wrapper';

export default function IncomingOutgoingItemsDialog( { isPurchase = false } ) {
	const { closeModal } = useModalControls();
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const [ status, setStatus ] = useState( '' );
	const [ companyLocation, setCompanyLocation ] = useState( null );
	const [ dates, setDates ] = useState( [ startOfMonth( new Date() ), endOfDay( new Date() ) ] );
	const [ placedBy, setPlacedBy ] = useState( null );
	const [ type, setType ] = useState( null );
	const [ isGrouped, setIsGrouped ] = useState( false );
	
	const columns: Column<LineItem>[] = useMemo( () => {
		const columnsToRemoveWhenGrouped = [ '#', 'management:client', 'management:created-date', 'management:type' ];
		const allColumns = isPurchase ? basePurchaseColumns : baseOrderColumns();
		return isGrouped
			? allColumns.filter( ( column ) => !columnsToRemoveWhenGrouped.includes( column.Header as string ) )
			: allColumns;
	}, [ isGrouped ] );
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			maxWidth='xl'
			boxProps={{ sx: { height: '100vh', bgcolor: 'background.default' } }}
			primary={isPurchase ? t( 'management:incoming-items' ) : t( 'management:outgoing-items' )}
			icon={(
				<IconButton onClick={closeModal}>
					<CloseIcon/>
				</IconButton>
			)}>
			<Form
				noClose
				initialValues={{
					companyLocation: null,
					newDates       : [ startOfMonth( new Date() ), endOfDay( new Date() ) ],
					placedBy       : null,
					type           : isPurchase ? undefined : null,
				}}
				onSubmit={( values ) => {
					setCompanyLocation( values.companyLocation?.id );
					setDates( values.newDates );
					setPlacedBy( values.placedBy );
					setType( values.type );
				}}>
				<Fragment>
					<ItemReportFilters isGrouped={isGrouped} type={isPurchase ? 'purchase' : 'item'}/>
					<GraphqlTable<LineItem, QueryLineItemsReadArgs>
						searchable
						showFooter
						disableUrlSync
						queryKey='lineItems'
						query={LineItemsRead}
						columns={columns}
						hiddenTableColumns='itemReportsHiddenColumns'
						variables={{
							options: {
								filter: {
									store   : null,
									status  : status || undefined,
									purchase: isPurchase ? {
										companyLocation: companyLocation || undefined,
										deletedAt      : null,
										status         : { $nin: [ 'CANCELLED', 'DECLINED' ] },
										staff          : isOwner || isAdmin || staff.metadata?.viewOtherEntries
											? placedBy?.id || undefined
											: staff?.id,
										createdAt      : !isEmpty( dates.filter( Boolean ) )
											? {
												$gte: dates[ 0 ],
												$lte: dates[ 1 ],
											}
											: undefined,
									} : undefined,
									order   : isPurchase ? undefined : {
										companyLocation: companyLocation || undefined,
										type           : type || {
											$nin: [ 'ESTIMATE',
											        'BID',
											        'QUOTE',
											        'PROPOSAL',
											        'WORK_ORDER',
											        'ACCOUNT',
											        'STATEMENT' ],
										},
										deletedAt      : null,
										status         : { $nin: [ 'CANCELLED', 'STANDING' ] },
										staff          : isOwner || isAdmin || staff.metadata?.viewOtherEntries
											? placedBy?.id || undefined
											: staff?.id,
										createdAt      : !isEmpty( dates.filter( Boolean ) )
											? {
												$gte: dates[ 0 ],
												$lte: dates[ 1 ],
												
											} : undefined,
									},
								},
							},
						}}
						expandedComponent={( row ) => (
							<Paper sx={{ p: 1, width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
								<Typography color='text.primary'>
									{row.description
										? t( 'management:description-value', { description: row.description } )
										: t( 'common:no-description' )}
								</Typography>
							</Paper>
						)}
						initialState={{
							hiddenColumns: !isPurchase ? [
									'id',
									'order.companyLocation.address.line1',
									'order.serviceDate',
									'item.glcode',
									'order.type',
									'management:payment' ]
								: staff.metadata?.itemReportsHiddenColumns
									? staff.metadata?.itemReportsHiddenColumns
									: [ 'id',
									    'purchase.companyLocation.address.line1',
									    'purchase.serviceDate',
									    'management:payment' ],
							
						}}
						isGroupedBy={isGrouped}
						useQuickFilters={() => ItemReportQuickFilterActions( setStatus )}
						headerExtraActions={{
							items: [ {
								name       : t( 'common:group-items' ) as string,
								icon       : <PlaylistAddIcon/>,
								buttonProps: {
									variant: 'outlined',
									sx     : { animation: isGrouped ? 'ripple2 1s linear infinite' : undefined },
								},
								onClick    : () => setIsGrouped( ( prev ) => !prev ),
							} ],
						}}
					/>
				</Fragment>
			</Form>
		</PageWrapper>
	);
}
