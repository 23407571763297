import { lineItemsStatusColors } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import { CompanyTagsType } from '@/modals/companyTags';
import { Company } from '@/types/schema';
import { Fade, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LineItemTagChip( {
	tooltip,
	status,
	company,
	removePlaceholder,
	loading,
}: {
	status: string,
	company: Company,
	removePlaceholder?: boolean,
	tooltip?: string,
	loading?: boolean
} ) {
	const theme = useTheme();
	const { t } = useTranslation();
	
	const lineItemTags: CompanyTagsType[] = company?.tags?.lineItemTags?.filter( ( tag ) => tag?.forOrder ) || [];
	
	const colorsMap = lineItemsStatusColors( lineItemTags, theme.palette.mode === 'dark' );
	
	const content = (
		<Typography
			sx={{
				color     : colorsMap?.[ status ] ? `${colorsMap[ status ]} !important` : undefined,
				fontWeight: '500',
			}}>
			{status || ( removePlaceholder ? '' : t( 'commerce:add-status' ) )}
		</Typography>
	);
	
	if ( loading ) return (
		<Fade in timeout={900}>
			<Skeleton
				animation='wave'
				height={10}
				sx={{ borderRadius: 1 }}
				width={50}
			/>
		</Fade>
	);
	
	if ( tooltip ) {
		return (
			<Tooltip title={tooltip}>
				{content}
			</Tooltip>
		);
	}
	
	return content;
}
